import {action, computed, observable} from 'mobx';
import {UserApi} from './userApi';

const TOKEN_STR = 'jwtToken';

class UserStore {
  @observable token = null;
  @observable user = null;

  @computed get isLoggedIn() {
    return !!this.token;
  }

  @action.bound
  setToken(token, rememberMe = false) {
    this.token = token;
    if (rememberMe) {
      localStorage.setItem(TOKEN_STR, token);
    }
  }

  @action.bound
  removeToken() {
    this.token = null;
    localStorage.removeItem(TOKEN_STR);
  }

  @action
  tryGetToken() {
    this.token = localStorage.getItem(TOKEN_STR);
  }

  @action
  login(id, password, rememberMe = false) {
    return UserApi.login(id, password).then(res => {
      if (res.ok) {
        return res
            .json()
            .then(res => res.access)
            .then(token => this.setToken(token, rememberMe))
            .then(UserApi.me)
            .then(res => res.json())
            .then(this.meSuccess);
      }
      throw 'Login failed';
    })
  }

  @action.bound
  meSuccess(user) {
    if (!user.is_staff) {
      this.removeToken();
      throw 'Not a staff';
    }
    this.user = user;
  }

  @action.bound
  logout() {
    this.removeToken();
  }
}

export const userStore = new UserStore();