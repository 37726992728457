import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {observer} from 'mobx-react';
import {userStore} from '../../lib/services/User/userStore';
import {IndexLinkContainer} from 'react-router-bootstrap';

@observer
export class Header extends React.Component {

  handleLogout = () => {
    userStore.logout();
  };

  render() {
    return (
        <Navbar variant="dark" bg="primary" expand="lg">
          <IndexLinkContainer to={'/'}>
            <Navbar.Brand>
              毫厘科技
            </Navbar.Brand>
          </IndexLinkContainer>
          {
            userStore.isLoggedIn &&
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
          }
          {
            userStore.isLoggedIn &&
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                <NavDropdown title="操作" id="basic-nav-dropdown">
                  <IndexLinkContainer to={'lab-calibration'}>
                    <NavDropdown.Item>实验室校准</NavDropdown.Item>
                  </IndexLinkContainer>
                  <NavDropdown.Divider/>
                  <IndexLinkContainer to={'/client/add'}>
                    <NavDropdown.Item>添加客户</NavDropdown.Item>
                  </IndexLinkContainer>
                  <IndexLinkContainer to={'/project/add'}>
                    <NavDropdown.Item>添加项目</NavDropdown.Item>
                  </IndexLinkContainer>
                </NavDropdown>
                <IndexLinkContainer to={'project'}>
                  <Nav.Link>项目列表</Nav.Link>
                </IndexLinkContainer>
              </Nav>
              <Nav>
                <Nav.Link onClick={this.handleLogout}>退出</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          }
        </Navbar>
    );
  }
}