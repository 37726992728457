import {Request} from '../../utils/request';
import {API_BASE_URL} from '../../../config';

export class UserApi {
  static login(id, password) {
    return Request.post(API_BASE_URL + '/auth/login/', {
      body: JSON.stringify({id, password})
    });
  }

  static me() {
    return Request.get(API_BASE_URL + '/user/me/');
  }
}