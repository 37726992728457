import React, {Component} from 'react';
import './App.css';
import {Header} from './components/Header';
import {observer} from 'mobx-react';
import {userStore} from './lib/services/User/userStore';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import routes from './routes';

@observer
class App extends Component {
  constructor(props) {
    super(props);
    userStore.tryGetToken();
    this.idRef = React.createRef();
    this.passwordRef = React.createRef();
    this.checkboxRef = React.createRef();
  }

  state = {
    loggingIn: false,
    isInvalid: false,
  };

  handleSubmit = (event) => {
    this.setState({loggingIn: true});
    this.setState({isInvalid: false});
    event.preventDefault();
    event.stopPropagation();
    const id = this.idRef.current.value;
    const password = this.passwordRef.current.value;
    const rememberMe = this.checkboxRef.current.checked;
    userStore.login(id, password, rememberMe)
        .then(() => this.setState({loggingIn: false}))
        .catch(() => {
          this.setState({loggingIn: false});
          this.setState({isInvalid: true});
        });
  };

  render() {
    const {isInvalid} = this.state;
    return (
        <Router>
          <div>
            <Header/>
            <Container style={{paddingTop: '20px'}}>
              {
                userStore.isLoggedIn
                    ?
                    routes.map((route, index) => (
                        <Switch>
                          <Route
                              key={index}
                              path={route.path}
                              exact={route.exact}
                              component={route.component}
                          />
                        </Switch>
                    ))
                    :
                    <Form>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Control placeholder="手机/邮箱" isInvalid={isInvalid} ref={this.idRef} required/>
                        <Form.Control.Feedback type="invalid">
                          管理员登录失败，请重新输入
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="formBasicPassword">
                        <Form.Control type="password" placeholder="密码" isInvalid={isInvalid} ref={this.passwordRef}
                                      required/>
                      </Form.Group>
                      <Form.Group controlId="formBasicChecbox">
                        <Form.Check type="checkbox" label="下次自动登录" ref={this.checkboxRef} custom/>
                      </Form.Group>
                      <Button variant="primary" onClick={this.handleSubmit}>
                        {this.state.loggingIn ? '登录中...' : '登录'}
                      </Button>
                    </Form>
              }
            </Container>
          </div>
        </Router>
    );
  }
}

export default App;
