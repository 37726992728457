import React from 'react';
import {observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';

@observer
export class Home extends React.Component {
  render() {
    return (
      <div>
        <ListGroup variant="flush">
          <ListGroup.Item>
            <Link to={'/lab-calibration'}>
              实验室校准
            </Link>
          </ListGroup.Item>
          <ListGroup.Item>
            <Link to={'/client/add'}>
              添加客户
            </Link>
          </ListGroup.Item>
          <ListGroup.Item>
            <Link to={'/project/add'}>
              添加项目
            </Link>
          </ListGroup.Item>
        </ListGroup>,
      </div>
    );
  }
}